<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ProducerOrderTable ref="orderTable" title="Ordini fornitore" :orders="orders" :pagination="paginationOptions"
          :hasStatusFilter="false" v-on:rowclick="rowClick" />
      </div>
    </div>

    <ModalOrdineProduttore ref="modalOrdine" @orderChanged="orderChanged"
      v-on:orderStatusChanged="orderStatusChanged" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ProducerOrderTable from "../Tables/ProducerOrderTable.vue";
import ModalOrdineProduttore from "../Modal/ModalOrdineProduttore.vue";
import { Disposable } from "@/TypedEvent";
import ProducerOrder from '@/entity/ProducerOrder';

@Component({
  components: {
    ProducerOrderTable,
    ModalOrdineProduttore,
  },
})
export default class OrdiniEvasi extends Vue {
  public orders = new Array<ProducerOrder>();
  public paginationOptions = { perPage: 25, perPageOptions: [25, 50, 100] };
  private onNewOrder: Disposable;

  public async rowClick(orderID: string) {
    const order = this.orders.find((item) => item.ID == orderID);
    if (order === undefined) return;

    (<ModalOrdineProduttore>this.$refs.modalOrdine).open(order);
  }

  public orderChanged(order: ProducerOrder) {
    console.log(order);
  }

  public orderStatusChanged(order: ProducerOrder) {
    //   const orderInArray = this.orders.find((item) => {
    //     return item.orderID == order.ID;
    //   });
    //   if (orderInArray !== undefined) orderInArray.status = order.lastStatus.status;
  }

  async mounted() {
    this.orders = await ProducerOrder.getOrders();

    //   this.onNewOrder = Session.instance.onNewOrder.on(async (order) => {
    //     this.orders.push(await OrderStore.getOrder(order.ID));

    //     (<ProducerOrderTable>this.$refs.orderTable).forceSort();
    //   });
  }

  beforeDestroy() {
    this.onNewOrder?.dispose();
  }
}
</script>
