import Vue from 'vue';
import DashboardLayout from './components/Layout/DashboardLayout.vue';

// GeneralViews
import NotFound from './views/NotFoundPage.vue';

// Dashboard
import Dashboard from './views/Dashboard/Dashboard.vue';

// Ordini
import OrdiniInArrivo from './views/Ordini/OrdiniInArrivo.vue';
import OrdiniEvasi from './views/Ordini/OrdiniEvasi.vue';
import OrdiniRifiutati from './views/Ordini/OrdiniRifiutati.vue';
import OrdiniFornitore from './views/Ordini/OrdiniFornitore.vue';

// Azienda
import GestioneAzienda from './views/Azienda/GestioneAzienda/GestioneAzienda.vue';
import GestioneUtenti from './views/Azienda/GestioneUtenti/GestioneUtenti.vue';

// Login
import Login from './views/Login/Login.vue';
import UserBusinessPermission from './entity/UserBusinessPermission/UserBusinessPermission';

export interface MetaRoute extends Pick<UserBusinessPermission, 'canEditOrder' | 'canManageAccounts' | 'canManageStore' | 'canEditClient' | 'canReceiveNotifications'> {
    requiresAuth: boolean;
}

const ordiniMenu = {
    path: '/ordini',
    component: DashboardLayout,
    redirect: '/ordini/nuovi',
    children: [
        {
            path: 'nuovi',
            Name: 'Nuovi ordini',
            component: OrdiniInArrivo,
            meta: <MetaRoute>{ requiresAuth: true, canEditOrder: true },
        },
        {
            path: 'evasi',
            Name: 'Ordini evasi',
            component: OrdiniEvasi,
            meta: <MetaRoute>{ requiresAuth: true },
        },
        {
            path: 'rifiutati',
            Name: 'Ordini rifiutati',
            component: OrdiniRifiutati,
            meta: <MetaRoute>{ requiresAuth: true },
        },
        {
            path: 'fornitore',
            Name: 'Ordini fornitore',
            component: OrdiniFornitore,
            meta: <MetaRoute>{ requiresAuth: true },
        },
    ],
};

const aziendaMenu = {
    path: '/azienda',
    component: DashboardLayout,
    redirect: '/azienda/azienda',
    children: [
        {
            path: 'gestione-azienda',
            Name: 'Gestione azienda',
            component: GestioneAzienda,
            meta: <MetaRoute>{ requiresAuth: true, canManageStore: true },
        },
        {
            path: 'utenti',
            Name: 'Gestione utenti',
            component: GestioneUtenti,
            meta: <MetaRoute>{ requiresAuth: true, canManageAccounts: true },
        },
    ],
};

const loginPage = {
    path: '/login',
    name: 'Login',
    component: Login,
};
const producerLoginPage = {
    path: '/producer/login',
    name: 'Producer Login',
    component: Login,
};

const routes = [
    {
        path: '/',
        redirect: () => {
            if (Vue.cookies.isKey('token')) {
                return '/dashboard';
            }
            return '/login';
        },
    },
    {
        path: '/',
        component: DashboardLayout,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: { requiresAuth: true },
            },
        ],
    },
    ordiniMenu,
    aziendaMenu,
    loginPage,
    producerLoginPage,
    { path: '*', component: NotFound },
];

export default routes;
