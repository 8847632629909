import { Type } from 'class-transformer';
import RequestManager from './RequestManager';
import Producer from './Producer/Producer';

export default class UserProducer extends RequestManager {
    public ID: number;
    public firstname: string;
    public lastname: string;
    public mail: string;
    @Type(() => Producer)
    public producer: Producer;
}
