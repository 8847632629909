import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import OrderStatus, { OrderStatuses } from '../../entity/OrderStatus';
import OrderStore, { ISearchOrdersParameters } from '../../entity/OrderStore';
import { ElTable } from 'element-ui/types/table';
import Helper from '../../util/Helper';
import Session from '@/Session';
import ModalOrdine from '@/views/Modal/ModalOrdine.vue';
import { Disposable } from '@/TypedEvent';

interface IOrderTable {
    ID: string;
    fullName: string;
    createdAt: string;
    dueDate: string;
    status: OrderStatuses;
    orderStatus: string;
    orderStatusDescription: string;
}

interface IPagination {
    perPage: number;
    currentPage: number;
    perPageOptions: Array<number>;
    total: number;
}

@Component({ name: 'OrderTable', components: { ModalOrdine } })
export default class OrderTable extends Vue {
    @Prop({ required: true, default: '' })
    public title: string;

    @Prop({ required: false, default: true })
    public hasStatusFilter: boolean;

    @Prop({ required: false, default: false })
    public hasDateFilter: boolean;

    @Prop({
        required: false,
        default: () => {
            return <ISearchOrdersParameters>{};
        },
    })
    public searchFilter: ISearchOrdersParameters;

    private table: ElTable;

    public statusFilter = OrderStatus.getArrayObject('value', 'text');

    @Prop({ required: true, default: new Array<OrderStore>() })
    orders: Array<OrderStore>;

    private searchResults = new Array<OrderStore>();
    private sortedData = new Array<OrderStore>();
    private filteredData = new Array<OrderStore>();

    public dateRangeResult = new Array<Date>();

    public searchQuery = '';

    private sortingOption = new Object();

    private isSorting = false;
    private isFiltering = false;
    public isSearching = false;
    private searched = false;

    public pickerDateSel = 'Creazione';

    @Prop({
        required: false,
        default: () => {
            return { perPage: 10, currentPage: 1, perPageOptions: [10, 25, 50], total: 0 };
        },
    })
    pagination: IPagination;

    private onOrderStatusChangedListener: Disposable;

    private get tableData(): IOrderTable[] {
        if (this.isSorting && !this.isFiltering) {
            return this.sortedData.map((order) => this.mapOrder(order));
        } else if (this.isFiltering || this.isSorting) {
            return this.filteredData.map((order) => this.mapOrder(order));
        } else if (this.searched) {
            return this.searchResults.map((order) => this.mapOrder(order));
        }

        return this.orders.map((order) => this.mapOrder(order));
    }

    public get pagedData() {
        return this.tableData.slice(this.from, this.to);
    }

    public get to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    }

    public get from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    }

    public get total() {
        this.pagination.total = this.tableData.length;
        return this.tableData.length;
    }

    public get canCreateOrder() {
        return Session.instance.userBusiness.permission.canCreateOrder;
    }

    public rowClick(val?: IOrderTable) {
        this.table.setCurrentRow(null);

        if (val != null) {
            this.$emit('rowclick', val.ID);
        }
    }

    public async search(query: string | Object | KeyboardEvent) {
        if (typeof query === 'string') {
            this.searchQuery = query;
        }

        if (this.searchQuery === '' && this.searched) {
            this.searched = false;
            this.isSorting = false;
            this.isFiltering = false;

            this.table.clearSort();
            this.table.clearFilter();
            return;
        } else if (this.searchQuery === '' && !this.searched) {
            return;
        }

        if (query instanceof KeyboardEvent && query.keyCode === 13) {
            this.isSearching = true;

            //this.searchFilter.find = this.searchQuery;
            this.searchResults = await OrderStore.getOrders({ find: this.searchQuery });

            this.searched = true;
            this.isSearching = false;
        } else if (query instanceof Object && !(query instanceof KeyboardEvent)) {
            this.isSearching = true;

            //this.searchFilter.find = this.searchQuery;
            this.searchResults = await OrderStore.getOrders({ find: this.searchQuery });

            this.searched = true;
            this.isSearching = false;
        }
    }

    public async searchOrders(query: string, callback: (data: Array<any>) => void) {
        this.searchFilter.find = query;
        let searchArray = await OrderStore.getOrders(this.searchFilter);
        let result = [];

        let map = new Map();
        for (const item of searchArray) {
            if (item.orderID.toLowerCase().includes(query.toLowerCase())) {
                result.push({
                    value: item.orderID,
                    order: item,
                });
            } else if (!map.has(item.fullname)) {
                map.set(item.fullname, true);
                result.push({
                    value: item.fullname,
                    order: item,
                });
            }
        }

        callback(result);
    }

    @Watch('dateRangeResult')
    dateRangeResultChanged(val, oldVal) {
        console.log(val);
    }

    private lastSort: any;

    public forceSort() {
        if (!this.isSorting) {
            return;
        }

        this.onTableSort(this.lastSort);
    }

    public onTableSort(data) {
        if (data.order == null) {
            this.isSorting = false;
        } else {
            this.isSorting = true;
        }

        if (this.isSorting) {
            this.lastSort = data;
            this.sortingOption = data;

            if (data.prop == 'createdAt') {
                if (this.isFiltering) this.filteredData = this.orderArrayByDate(this.filteredData, 'createdAt', data.order);
                else if (!this.isFiltering) this.sortedData = this.orderArrayByDate(this.searched ? this.searchResults : this.orders, 'createdAt', data.order);
            } else if (data.prop == 'dueDate') {
                if (this.isFiltering) this.filteredData = this.orderArrayByDate(this.filteredData, 'dueDate', data.order);
                else if (!this.isFiltering) this.sortedData = this.orderArrayByDate(this.searched ? this.searchResults : this.orders, 'dueDate', data.order);
            }
        }
    }

    private lastFilter: any;

    public forceFilter() {
        if (!this.isFiltering) {
            return;
        }

        this.onTableFilter(this.lastFilter);
    }

    public onTableFilter(filters) {
        if (filters.status.length > 0) this.isFiltering = true;
        else this.isFiltering = false;

        if (this.isFiltering) {
            this.lastFilter = filters;

            if (this.isSorting) this.filteredData = this.filterArray(this.sortedData, filters);
            else this.filteredData = this.filterArray(this.searched ? this.searchResults : this.orders, filters);
        } else this.onTableSort(this.sortingOption);
    }

    private orderStatus(order: OrderStore) {
        const dueDate = order.dueDate;
        const status = order.status;

        if (status == 1 || status == 2 || status == 4) {
            if (dueDate < new Date()) return { status: 'danger', description: 'Scaduto' };
            else if (dueDate >= new Date() && dueDate <= new Date(new Date().setDate(new Date().getDate() + 2))) return { status: 'warning', description: 'In scadenza' };
        }
    }

    public statusFormatter(row: IOrderTable): string {
        // console.log(row.status);
        return OrderStatuses.toString(row.status);
    }

    public statusTagType(row: IOrderTable) {
        switch (row.status) {
            case 0:
                return 'primary';
            case 1:
                return 'warning';
            case 2:
                return 'success';
            case 3:
                return 'danger';
            case 4:
                return 'success';
            case 5:
                return 'success';
            case 6:
                return 'info';
            default:
                return '';
        }
    }

    public async tableNextPage(page: number) {
        if (page >= Math.ceil(this.total / this.pagination.perPage) - 1 && !this.searched) {
            this.isSearching = true;

            let current = this.orders.length + 1;

            this.searchFilter.from = current;
            this.searchFilter.to = current + this.pagination.perPage;
            this.orders.push(...(await OrderStore.getOrders(this.searchFilter)));

            this.isSearching = false;
        }
    }

    private mapOrder(order: OrderStore): IOrderTable {
        const orderStatus = this.orderStatus(order);

        return {
            ID: order.orderID,
            createdAt: Helper.prittifyDate(order.createdAt),
            dueDate: Helper.prittifyDate(order.dueDate),
            fullName: order.fullname,
            status: order.status,
            orderStatus: orderStatus?.status,
            orderStatusDescription: orderStatus?.description,
        };
    }

    private orderArrayByDate(array: Array<OrderStore>, prop: string, order: 'ascending' | 'descending') {
        return array.slice().sort((a, b) => {
            if (order === 'ascending') {
                return a[prop] - b[prop];
            } else if (order === 'descending') {
                return b[prop] - a[prop];
            }
        });
    }

    private filterArray(array: Array<OrderStore>, filters) {
        return array.filter((item) => {
            return filters.status.includes(parseInt(<any>item.status));
        });
    }

    public openNewOrder() {
        (<any>this.$refs.modalOrdine).open();
    }

    public mounted() {
        this.table = <ElTable>this.$refs.ordiniTable;

        if (this.searchFilter.status != null) {
            this.statusFilter = this.statusFilter.filter((x) => {
                return this.searchFilter.status.includes((<any>x).value);
            });
        }

        this.onOrderStatusChangedListener = Session.instance.onOrderStatusChanged.on(this.onOrderStatusChanged);
    }

    private onOrderStatusChanged(id: string) {
        // TODO: update the status tag if is the id is in the table data
    }

    public beforeDestroy() {
        this.onOrderStatusChangedListener.dispose();
    }

    @Watch('pagination.perPage')
    private async paginationChanged(val: number, oldVal: number): Promise<void> {
        if (val === 1) {
            await this.tableNextPage(1);
        }
    }
}
