import 'reflect-metadata';
import 'src/Extensions';
import Vue from 'vue';
import './pollyfills';
import VueRouter, { RouteRecord } from 'vue-router';
import VueRouterPrefetch from 'vue-router-prefetch';
// import VueNotify from 'vue-notifyjs';
import it from 'vee-validate/dist/locale/it';
import VeeValidate, { Validator } from 'vee-validate';
import locale from 'element-ui/lib/locale/lang/it';
import App from './App.vue';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import SideBar from './components/UIComponents/SidebarPlugin';
import progress from './progressbar';
import Element from 'element-ui';

// router setup
import routes, { MetaRoute } from './routes';

// library imports

import './assets/sass/paper-dashboard.scss';

import sidebarLinks from './sidebarLinks';
import './registerServiceWorker';

import VueCookies from 'vue-cookies-ts';
import Session from './Session';

// plugin setup
Vue.use(Element, { locale });
Vue.use(VueRouter);
Vue.use(VueRouterPrefetch);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
// Vue.use(VueNotify);
Vue.use(SideBar, { sidebarLinks: sidebarLinks });
Vue.use(VeeValidate, { fieldsBagName: 'formFields' });
Validator.localize('it', it);
Vue.use(VueCookies);

Vue.prototype.$forceCompute = function (computedName, forceUpdate /* default: true */) {
    if (this._computedWatchers[computedName]) {
        this._computedWatchers[computedName].run();
        if (forceUpdate || typeof forceUpdate == 'undefined') this.$forceUpdate();
    }
};

// configure router
export const router = new VueRouter({
    routes, // short for routes: routes
    linkActiveClass: 'active',
    mode: 'history',
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});

progress.initProgress(router);

router.beforeEach((to, from, next) => {
    if (to.path === '/login' || to.path === '/producer/login') {
        next();
        return;
    }

    const authorizations = getAuthorizations(to.matched);
    if (!authorizations.requiresAuth) {
        next();
        return;
    }

    // if (!Session.instance.checkToken()) {
    //     stopNext(next);
    //     next('/login');
    //     return;
    // }

    delete authorizations.requiresAuth;

    if (Session.instance.userBusiness != null) {
        if (Session.instance.userBusiness.permission.canAccess(authorizations)) {
            next();
        } else {
            stopNext(next);
            next(from.path);
        }
    } else if (Session.instance.userProducer != null) {
        next();
    } else {
        next();
        // stopNext(next);
        // next(from.path);
    }
});

function getAuthorizations(routeRecords: RouteRecord[]): MetaRoute {
    const result = <MetaRoute>{ requiresAuth: false, canEditClient: false, canEditOrder: false, canManageAccounts: false, canManageStore: false, canReceiveNotifications: false };

    routeRecords.forEach((record) => {
        const meta = <MetaRoute>record.meta;

        if (meta.requiresAuth) result.requiresAuth = true;
        if (meta.canEditClient) result.canEditClient = true;
        if (meta.canEditOrder) result.canEditOrder = true;
        if (meta.canManageAccounts) result.canManageAccounts = true;
        if (meta.canManageStore) result.canManageStore = true;
        if (meta.canReceiveNotifications) result.canReceiveNotifications = true;
    });

    return result;
}

function stopNext(next) {
    next(false);
    progress.stopProgress();
}

/* eslint-disable no-new */
new Vue({
    el: '#app',
    render: (h) => h(App),
    router,
});
