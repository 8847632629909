<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <div class="card-header" style="text-align: center">
                    <img src="/static/img/easyfito-logo.png" alt="Logo Easyfito" />
                  </div>
                  <br />
                  <lg-input type="email" name="email" placeholder="Email" v-validate="modelValidations.email"
                    :error="getError('email')" v-model="model.email" addon-left-icon="nc-icon nc-single-02"
                    v-on:input="validate()"></lg-input>
                  <lg-input type="password" name="password" placeholder="Password"
                    v-validate="modelValidations.password" :error="getError('password')" v-model="model.password"
                    addon-left-icon="nc-icon nc-key-25" v-on:input="validate()"></lg-input>

                  <br />

                  <p-button native-type="submit" slot="footer" type="leaf" round block class="mb-3"
                    v-bind:disabled="!isValidForm" v-loading="isLoading">Login</p-button>

                  <div slot="footer">
                    <div class="pull-left">
                      <h6>
                        <router-link class="link footer-link" to="/recoverme">Recupera password</router-link>
                      </h6>
                    </div>

                    <div class="pull-right">
                      <h6>
                        <a href="https://confluence.solutiontech.tech/plugins/servlet/share/content/c3c1a280-7b1a-4246-b2b6-194be7b9b842"
                          target="_blank" class="link footer-link">Aiuto?</a>
                      </h6>
                    </div>
                  </div>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="
            background-image: url(/static/img/background/background-easyfito.png);
          "></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from "../../components/UIComponents";
import LoginInput from "../../components/UIComponents/Inputs/LoginInput";
import AppFooter from "../../components/Layout/AppFooter";
import Session from "../../Session";

export default {
  components: {
    AppFooter,
    [Button.name]: Button,
    [LoginInput.name]: LoginInput,
  },
  data() {
    return {
      isValidForm: false,
      isLoading: false,
      model: {
        email: "",
        password: "",
      },
      modelValidations: {
        email: {
          required: true,
          email: true,
        },
        password: {
          required: true,
          min: 1,
        },
      },
    };
  },
  mounted() {
  },
  methods: {
    async validate() {
      this.isValidForm = await this.$validator.validateAll();
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async login() {
      if (!this.isValidForm) {
        return;
      }

      try {
        this.isLoading = true;

        if (this.$route.name === 'Producer Login') {
          await Session.instance.producerLogin(this.model.email, this.model.password);
        } else if (this.$route.name === 'Login') {
          await Session.instance.login(this.model.email, this.model.password);
        }

        this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
        this.$message.error({
          message: "Login fallito",
          type: "error",
          duration: 5000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: none;
}
</style>
