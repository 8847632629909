import RequestManager from './RequestManager';

export enum ProducerOrderStatuses {
    New,
    ModifiedByProducer,
    ModifiedByPromonord,
    UnderReviewByProducer,
    Approved,
    Rejected,
    Deleted,
    Closed,
}

export namespace ProducerOrderStatuses {
    export function color(status: ProducerOrderStatuses): string {
        switch (status) {
            case ProducerOrderStatuses.ModifiedByProducer:
            case ProducerOrderStatuses.ModifiedByPromonord:
            case ProducerOrderStatuses.UnderReviewByProducer:
                return '#e6a23c';
            case ProducerOrderStatuses.Approved:
            case ProducerOrderStatuses.Closed:
                return '#67c23a';
            case ProducerOrderStatuses.Rejected:
            case ProducerOrderStatuses.Deleted:
                return '#f56c6c';
            default:
                return '';
        }
    }

    export function tagColor(status: ProducerOrderStatuses): string {
        switch (status) {
            case ProducerOrderStatuses.New:
                return 'primary';
            case ProducerOrderStatuses.ModifiedByProducer:
            case ProducerOrderStatuses.ModifiedByPromonord:
            case ProducerOrderStatuses.UnderReviewByProducer:
                return 'warning';
            case ProducerOrderStatuses.Approved:
                return 'success';
            case ProducerOrderStatuses.Rejected:
            case ProducerOrderStatuses.Deleted:
                return 'danger';
            case ProducerOrderStatuses.Closed:
                return 'info';
            default:
                return 'primary';
        }
    }

    export function toString(status: ProducerOrderStatuses): string {
        switch (status) {
            case ProducerOrderStatuses.New:
                return 'Nuovo ordine';
            case ProducerOrderStatuses.ModifiedByProducer:
                return 'Modificato dal produttore';
            case ProducerOrderStatuses.ModifiedByPromonord:
                return 'Modificato da Easyfito';
            case ProducerOrderStatuses.UnderReviewByProducer:
                return 'In approvazione';
            case ProducerOrderStatuses.Approved:
                return 'Accettato';
            case ProducerOrderStatuses.Rejected:
                return 'Rifiutato';
            case ProducerOrderStatuses.Deleted:
                return 'Cancellato';
            case ProducerOrderStatuses.Closed:
                return 'Chiuso';
            default:
                return 'Sconosciuto';
        }
    }

    export function canEdit(status: ProducerOrderStatuses): boolean {
        switch (status) {
            case ProducerOrderStatuses.New:
                return true;
            case ProducerOrderStatuses.ModifiedByProducer:
            case ProducerOrderStatuses.ModifiedByPromonord:
            case ProducerOrderStatuses.UnderReviewByProducer:
                return true;
            case ProducerOrderStatuses.Approved:
                return false;
            case ProducerOrderStatuses.Rejected:
            case ProducerOrderStatuses.Deleted:
                return false;
            case ProducerOrderStatuses.Closed:
                return false;
            default:
                return false;
        }
    }
}

export default class ProducerOrderStatus {
    /**
     *
     * @param prop1 Contiene il valore numerico
     * @param prop2 Contiene il risultato di statusToString
     * @param freeze Se true applica Object.freeze ad ogni oggetto. Default true
     */
    static getArrayObject(prop1: string, prop2: string, freeze = true): Array<Object> {
        return Object.values(ProducerOrderStatus)
            .map((item) => {
                if (typeof item === 'number') {
                    const a = new Object();
                    const value = ProducerOrderStatuses.toString(item);

                    Object.defineProperty(a, prop1, { value: item, writable: false });
                    Object.defineProperty(a, prop2, { value: value, writable: false });
                    if (freeze) Object.freeze(a);

                    return a;
                }
            })
            .filter((item) => {
                return typeof item !== 'undefined';
            });
    }
}

export class ProducerOrderStatusUpdate extends RequestManager {
    public status: ProducerOrderStatuses;
}
