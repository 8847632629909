<template>
  <SlideYUpTransition :duration="animationDuration">
    <div class="modal fade" @click.self="closeModal" :class="[{ 'show d-block': show }, { 'd-none': !show }]"
      v-show="show" tabindex="-1" role="dialog" :aria-hidden="!show">
      <div class="modal-dialog" :class="[modalClasses]">
        <div class="modal-content">
          <div class="modal-header" v-if="showHeader" :class="headerClasses">
            <slot name="close-button">
              <button type="button" v-if="showClose" @click="closeModal" class="close" data-dismiss="modal"
                :aria-hidden="!show">
                <i class="nc-icon nc-simple-remove"></i>
              </button>
            </slot>

            <div class="row mx-auto">
              <div class="col-12">
                <div class="
                    modal-profile
                    d-flex
                    justify-content-center
                    align-items-center
                    mx-auto
                  ">
                  <i id="headerLogo" class="fas fa-plus-circle" />
                </div>
              </div>
              <div class="col-12">
                <h4 class="title" style="color: #606266">Aggiungi prodotto</h4>
              </div>
            </div>
          </div>

          <el-form :model="form" :rules="rules" ref="form" label-width="120px" style="padding: 20px; margin-top: 30px">
            <el-form-item label="Prodotto" prop="product">
              <el-select v-model="form.product.selected" v-on:change="productSelected" :remote-method="remoteMethod"
                :loading="form.product.loading" filterable remote reserve-keyword placeholder="Cerca un prodotto">
                <el-option v-for="item in form.product.options" :key="item.value" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="Taglia" prop="productSize">
              <el-select v-model="form.productSize.selected" :disabled="!form.productSize.enabled"
                v-on:change="productSizeSelected">
                <el-option v-for="item in form.productSize.options" :key="item.value" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="Quantità" required>
              <el-input-number v-model="form.quantity.value" :min="1" :disabled="!form.quantity.enabled" />
            </el-form-item>
            <el-form-item>
              <el-button @click="closeModal">Chiudi</el-button>
              <el-button type="primary" @click="submitForm">Aggiungi</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";
import Product from "@/entity/Product";

export default {
  name: "addProductOrdine",
  components: {
    SlideYUpTransition,
  },
  props: {
    show: Boolean,
    producerName: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    modalClasses: {
      type: [Object, String],
      description: "Modal dialog css classes",
    },
    headerClasses: {
      type: [Object, String],
      description: "Modal Header css classes",
    },
    bodyClasses: {
      type: [Object, String],
      description: "Modal Body css classes",
    },
    footerClasses: {
      type: [Object, String],
      description: "Modal Footer css classes",
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration",
    },
  },
  methods: {
    closeModal() {
      this.$emit("update:show", false);
      this.$emit("close");
    },
    async remoteMethod(query) {
      if (query !== "") {
        this.form.product.loading = true;

        if (this.producerName !== "") {
          query += `&producer=${this.producerName}`;
        }

        this.form.product.products = await Product.getMany(
          `products?search=${query}`
        );

        this.form.product.options = this.form.product.products.map((item) => {
          return { value: item.ID, label: item.commercialName };
        });

        this.form.product.loading = false;
      } else {
        this.form.productSize.enabled = false;
        this.form.quantity.enabled = false;

        this.form.product.products = [];
        this.form.product.options = [];
      }
    },
    productSelected(selected) {
      let selectedProduct = this.form.product.products.find((item) => {
        return item.ID === selected;
      });

      this.form.productSize.options = selectedProduct.productSizes.map(
        (item) => {
          return { value: item.ID, label: item.getPackage };
        }
      );

      this.result.product = selectedProduct;

      this.form.productSize.enabled = true;
    },
    productSizeSelected(selected) {
      this.result.size = this.result.product.productSizes.find((item) => {
        return item.ID === selected;
      });

      this.form.quantity.enabled = true;
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.result.quantity = this.form.quantity.value;
          this.$emit("result", this.result);
          this.closeModal();
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    show(val) {
      let documentClasses = document.body.classList;

      if (val) {
        this.$refs.form.resetFields();

        Object.assign(this, initData());
        Object.assign(this, this.$options.data.apply(this));

        documentClasses.add("modalAddProduct-open");
      } else {
        documentClasses.remove("modalAddProduct-open");
      }
    },
  },
  data() {
    return initData();
  },
};

function initData() {
  let productValidator = (rule, value, callback) => {
    if (value.selected === "") {
      callback(new Error("Selezionare un prodotto"));
    } else {
      callback();
    }
  };

  let sizeValidator = (rule, value, callback) => {
    if (value.selected === "") {
      callback(new Error("Selezionare una taglia"));
    } else {
      callback();
    }
  };

  return {
    form: {
      product: {
        selected: "",
        products: [],
        options: [],
        loading: false,
      },
      productSize: {
        selected: "",
        options: [],
        enabled: false,
      },
      quantity: {
        value: 1,
        enabled: false,
      },
    },
    result: {
      product: null,
      size: null,
      quantity: 1,
    },
    rules: {
      product: [
        {
          validator: productValidator,
          required: true,
          trigger: "change",
        },
      ],
      productSize: [
        {
          validator: sizeValidator,
          required: true,
          trigger: "change",
        },
      ],
    },
  };
}
</script>

<style lang="scss">
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}

button>i+span {
  margin-left: 10px;
}

#headerLogo {
  color: $success-color;
}

.ordine-title {
  margin-top: 10px;
  font-size: 35px;
  font-weight: bold;
}

.ordine-subtitle {
  font-size: 25px;
  color: black;
}

.bigger {
  font-size: 20px;
}
</style>
