<template>
  <div>
    <SlideYUpTransition :duration="500">
      <div :class="[{ 'show d-block': show }, { 'd-none': !show }]" :aria-hidden="!show" class="modal fade"
        v-show="show" tabindex="-1" role="dialog" @click.self="show = false">
        <div class="modal-dialog big-modal">
          <div class="modal-content" v-loading="isLoading" element-loading-text="Caricamento in corso...">
            <div class="modal-header justify-content-center">
              <div class="
                  modal-profile
                  d-flex
                  justify-content-center
                  align-items-center
                ">
                <i class="fas fa-truck"></i>
              </div>
            </div>

            <div class="modal-body">
              <card type="testimonial" style="margin-top: 0px !important">
                <h4 class="card-title ordine-title">Stato ordine</h4>
              </card>
              <card>
                <div>
                  <h4 class="card-title">Modifica stato</h4>
                  <div class="row" style="margin-top: 20px">
                    <div class="col-md-6">
                      <div>
                        <p class="text-center">Stato</p>
                        <el-select class="select-info" style="display: block" placeholder="Seleziona"
                          v-model="statusPopover.selected">
                          <el-option v-for="status in statusPopover.statuses" :value="status.value"
                            :label="status.label" :key="status.label" :disabled="status.disabled" />
                        </el-select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <p class="text-center">Commento</p>
                        <fg-input :disabled="!statusPopover.selected" v-model="statusPopover.comment"
                          placeholder="(Facoltativo)" />
                      </div>
                    </div>
                  </div>
                  <el-button id="confirmStatusBtn" type="success" v-on:click="newStatus"
                    :hidden="!statusPopover.selected" :loading="statusPopover.loading">Conferma</el-button>
                </div>
              </card>
              <card>
                <h4 class="card-title" style="margin-bottom: 30px">
                  Cronologia
                </h4>

                <div class="scrollable-card">
                  <el-timeline :reverse="true">
                    <el-timeline-item placement="top" v-for="status in history" :key="status.ID" :icon="status.icon"
                      :type="status.type" :timestamp="status.timestamp" :size="'large'">
                      <el-card>
                        <div style="width: 100%; overflow: hidden" v-if="status.read">
                          <div class="read-dot" style="float: left">
                            <i class="fas fa-check" style="color: white" />
                          </div>
                          <p style="margin-left: 30px">Letto dal cliente</p>
                        </div>
                        <h4>{{ status.status }}</h4>
                        <p>{{ status.description }}</p>
                      </el-card>
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </card>
            </div>

            <div class="modal-footer">
              <p-button type="danger" class="left-btn" link @click="show = false">Chiudi</p-button>
            </div>
          </div>
        </div>
      </div>
    </SlideYUpTransition>
  </div>
</template>
<script lang="ts">
import OrderStatus, { OrderStatuses } from "@/entity/OrderStatus";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { SlideYUpTransition } from "vue2-transitions";

@Component({ name: "ModalStatusHistory", components: { SlideYUpTransition } })
export default class ModalStatusHistory extends Vue {
  public show = false;
  private hadOpenClass = false;
  public isLoading = true;

  public statusPopover = {
    selected: "",
    comment: "",
    statuses: [],
    visible: false,
    loading: false,
  };

  private orderID = "";

  private rawHistory = new Array<OrderStatus>();
  public get history() {
    return this.rawHistory.map((item) => {
      return {
        ID: item.ID,
        timestamp: item.createdAt.prittify(true),
        status: OrderStatuses.toString(item.status),
        description: item.description,
        type: this.statusToType(item.status),
        icon: this.statusToIcon(item.status),
        read: !item.unread,
      };
    });
  }

  private statusToType(status: OrderStatuses) {
    if (status == OrderStatuses.New) return "primary";
    else if (
      status == OrderStatuses.Approved ||
      status == OrderStatuses.Delivering ||
      status == OrderStatuses.Delivered ||
      status == OrderStatuses.Closed
    )
      return "success";
    else if (status == OrderStatuses.Pending) return "warning";
    else return "danger";
  }

  private statusToIcon(status: OrderStatuses) {
    if (status == OrderStatuses.New) return "fas fa-plus";
    else if (status == OrderStatuses.Approved) return "fas fa-check";
    else if (
      status == OrderStatuses.Delivering ||
      status == OrderStatuses.Delivered
    )
      return "fas fa-truck";
    else if (status == OrderStatuses.Pending) return "fas fa-hourglass-start";
    else if (status == OrderStatuses.Closed) return "fas fa-times";
    else return "fas fa-exclamation";
  }

  public async open(orderID: string) {
    Object.assign(this, this.initData());

    this.show = true;

    this.orderID = orderID;
    this.rawHistory = await OrderStatus.getMany(
      `orders/${orderID}/statushistory`
    );

    this.statusPopover.statuses = OrderStatus.getArrayObject(
      "value",
      "label",
      false
    );
    this.statusPopover.statuses.splice(0, 1);

    this.disableSelected();

    this.isLoading = false;
  }

  public async newStatus() {
    this.statusPopover.loading = true;

    const newStatus = new OrderStatus();
    newStatus.status = parseInt(this.statusPopover.selected);
    if (this.statusPopover.comment)
      newStatus.description = this.statusPopover.comment;

    const res = await newStatus.postOne(`orders/${this.orderID}/statushistory`);
    this.rawHistory.push(res);

    this.disableSelected();

    this.$emit("orderStatusChanged", res);

    this.statusPopover.selected = "";
    this.statusPopover.comment = "";

    this.statusPopover.loading = false;
    this.$message({
      showClose: true,
      message: "Stato dell'ordine modificato",
      type: "success",
    });
  }

  private disableSelected() {
    this.statusPopover.statuses.forEach((item) => {
      if (item.value == this.rawHistory[this.rawHistory.length - 1].status)
        item.disabled = true;
      else item.disabled = false;
    });
  }

  @Watch("show")
  private showChanged(val) {
    const documentClasses = document.body.classList;

    if (val) this.hadOpenClass = documentClasses.contains("modal-open");

    if (val && !this.hadOpenClass) {
      documentClasses.add("modal-open");
    } else if (!val && !this.hadOpenClass) {
      documentClasses.remove("modal-open");
    }
  }

  private initData() {
    return {
      isLoading: true,
      statusPopover: {
        selected: "",
        comment: "",
        statuses: [],
        visible: false,
        loading: false,
      },
      orderID: "",
      rawHistory: new Array<OrderStatus>(),
    };
  }
}
</script>
<style lang="scss">
.el-timeline-item__node--large {
  left: -7px;
  width: 24px;
  height: 24px;
}
</style>
<style lang="scss" scoped>
.scrollable-card {
  max-height: 350px;
  overflow: scroll;
}

.read-dot {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: rgb(64, 158, 255);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
