import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue';
import ModalOrdine from '@/views/Modal/ModalOrdine';
import OrderTable from '@/views/Tables/OrderTable';
import CalendarPopover from '@/views/Popovers/CalendarPopover.vue';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import itLocale from '@fullcalendar/core/locales/it';
import Order from '@/entity/Order';
import OrderStore from '@/entity/OrderStore';
import { RuntimeEventSubscriber } from '@/util/Helper';
import OrderStatus, { OrderStatuses } from '@/entity/OrderStatus';
import OrderStats from '@/entity/OrderStats';
import Session from '@/Session';
import { OptionsInput } from '@fullcalendar/core';
import ProducerOrderTable from '../Tables/ProducerOrderTable.vue';
import ModalOrdineProduttore from '../Modal/ModalOrdineProduttore.vue';
import ProducerOrder from '@/entity/ProducerOrder';

@Component({
    name: 'Dashboard',
    components: {
        FullCalendar,
        CircleChartCard,
        ModalOrdine: require('@/views/Modal/ModalOrdine.vue').default,
        OrderTable: require('@/views/Tables/OrderTable.vue').default,
        ProducerOrderTable,
        ModalOrdineProduttore,
    },
})
export default class Dashboard extends Vue {
    $refs: {
        calendar: FullCalendar;
        modalOrdine: ModalOrdine;
        modalOrdineProduttore: ModalOrdineProduttore;
        orderTable: OrderTable;
    };

    public charts = {
        new: 0,
        expiring: 0,
        expired: 0,
    };

    private calendarOrders = new Array<OrderStore>();

    public rawData = {
        orders: new Array<OrderStore>(),
    };

    public producerOrders = new Array<ProducerOrder>();
    public paginationOptions = { perPage: 25, perPageOptions: [25, 50, 100] };

    public calendarIsLoading = false;
    public calendarConfig: OptionsInput = {
        nextDayThreshold: '23:59:59',
        plugins: [dayGridPlugin, timeGridPlugin],
        locale: itLocale,
        defaultDate: new Date(),
        eventTimeFormat: { hour12: false, hour: '2-digit', minute: '2-digit' },
        header: {
            left: 'title',
            center: 'dayGridMonth,timeGridWeek,timeGridDay',
            right: 'prev,next,today',
        },
        views: {
            month: {
                titleFormat: {
                    month: 'long',
                    year: 'numeric',
                },
            },
            week: {
                titleFormat: {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric',
                },
            },
            day: {
                titleFormat: {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                },
            },
        },
        businessHours: true,
    };

    public get events() {
        return this.calendarOrders
            .filter((order) => {
                return OrderStatuses.isRunning(order.status);
            })
            .map((order) => {
                return {
                    start: order.dueDate,
                    title: order.fullname,
                    backgroundColor: OrderStatuses.color(order.status),
                    extendedProps: {
                        orderID: order.orderID,
                        statusString: OrderStatuses.toString(order.status),
                    },
                };
            });
    }

    public get isUserBusiness() {
        return Session.instance.userBusiness != null;
    }

    public eventRender(info) {
        const contentElement = info.el.querySelector('.fc-content');
        const CalendarPopoverConstructor = Vue.extend(CalendarPopover as any);
        const componentInstance = new CalendarPopoverConstructor({
            propsData: {
                title: info.event.extendedProps.orderID,
                status: info.event.extendedProps.statusString,
                parentElement: contentElement.parentElement,
            },
        });

        componentInstance.$mount();

        contentElement.parentElement.appendChild(componentInstance.$el);
    }

    public async datesRender(info) {
        this.calendarIsLoading = true;

        const calendar = this.$refs.calendar.getApi();
        const date = calendar.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        this.calendarOrders = await OrderStore.getOrders({ month: month, year: year });

        this.calendarIsLoading = false;
    }

    public orderStatusChanged(order: Order) {
        const orderInArray = this.rawData.orders.find((item) => {
            return item.orderID === order.ID;
        });

        if (orderInArray != null) orderInArray.status = order.lastStatus.status;

        const calendarOrder = this.calendarOrders.find((item) => {
            return item.orderID === order.ID;
        });

        if (calendarOrder != null) calendarOrder.status = order.lastStatus.status;
        else this.calendarOrders.push(new OrderStore(order));

        this.createCharts();
    }

    private async createCharts() {
        const result = await OrderStats.getStats();
        if (result == null) {
            return;
        }

        this.charts.new = this.calcPercentage(result.newOrders, result.totalActiveOrders) ?? 0;
        this.charts.expiring = this.calcPercentage(result.expiringOrders, result.totalActiveOrders) ?? 0;
        this.charts.expired = this.calcPercentage(result.expiredOrders, result.totalActiveOrders) ?? 0;
    }

    public async openOrdineModal(orderID) {
        // (<ModalOrdine>this.$refs.modalOrdine).open(await Order.getOne(`orders/${orderID}`));
        this.$refs.modalOrdine.open(await Order.getOne(`orders/${orderID}`));
    }

    public async calendarEventClick(info) {
        const event = info.event;

        this.openOrdineModal(event.extendedProps.orderID);
    }

    private calcPercentage(val: number, max: number) {
        const perc = Math.floor((val / max) * 100);

        return isNaN(perc) ? null : perc;
    }

    private refilterTable() {
        this.$refs.orderTable?.forceSort();
        this.$refs.orderTable?.forceFilter();
    }

    public async producerOrderClick(orderID: string) {
        const order = this.producerOrders.find((item) => item.ID == orderID);
        if (order === undefined) return;

        this.$refs.modalOrdineProduttore.open(order);
    }

    public producerOrderChanged(order: ProducerOrder) {
        console.log(order);
    }

    public producerOrderStatusChanged(order: ProducerOrder) {}

    public async mounted() {
        // if (this.isUserBusiness) {
        //     this.rawData.orders = await OrderStore.getOrders({
        //         from: 0,
        //         to: 50,
        //         status: [OrderStatuses.New, OrderStatuses.Pending, OrderStatuses.Approved, OrderStatuses.Delivering],
        //     });

        //     RuntimeEventSubscriber.add('orderStatusChanged', async (orderID) => {
        //         this.orderStatusChanged(await Order.getOne(`orders/${orderID}`));

        //         this.refilterTable();
        //     });

        //     Session.instance.onNewOrder.on(async (orderNotification) => {
        //         this.rawData.orders.push(await OrderStore.getOrder(orderNotification.ID));

        //         this.refilterTable();
        //     });

        //     this.createCharts();
        // } else {
            this.producerOrders = await ProducerOrder.getOrders();
            console.log(this.producerOrders);
        // }
    }

    public beforeDestroy() {
        RuntimeEventSubscriber.remove('orderStatusChanged', this.orderStatusChanged);
    }
}
