<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <order-table ref="orderTable" title="Ordini Evasi" :orders="orders" :searchFilter="localSearchFiler"
          :pagination="paginationOptions" v-on:rowclick="rowClick" />
      </div>
    </div>

    <ModalOrdine ref="modalOrdine" v-on:orderStatusChanged="orderStatusChanged" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import OrderStore from "../../entity/OrderStore";
import Order from "../../entity/Order";
import OrderStatus, { OrderStatuses } from "../../entity/OrderStatus";
import OrderTable from "../Tables/OrderTable.vue";
import ModalOrdine from "../Modal/ModalOrdine.vue";
import { Disposable } from "@/TypedEvent";
import Session from "@/Session";

@Component({
  components: {
    OrderTable,
    ModalOrdine,
  },
})
export default class OrdiniEvasi extends Vue {
  public orders = new Array<OrderStore>();
  public localSearchFiler = { status: [OrderStatuses.Delivered, OrderStatuses.Closed] };
  public paginationOptions = { perPage: 25, perPageOptions: [25, 50, 100] };
  private onOrderStatusChanged: Disposable;

  // TODO: check this method
  public async mounted() {
    this.orders = await OrderStore.getOrders({ status: [OrderStatuses.Delivered, OrderStatuses.Closed] });

    this.onOrderStatusChanged = Session.instance.onOrderStatusChanged.on(async (publicID) => {
      const lastStatus = await OrderStatus.getLast(publicID);

      if (lastStatus.status === OrderStatuses.Delivered || lastStatus.status === OrderStatuses.Closed) {
        const order = this.orders.find((orderStore) => {
          return orderStore.orderID === publicID;
        });

        if (order != null) {
          order.status = lastStatus.status;
          order.statusDescription = lastStatus.description;
          order.statusDate = lastStatus.createdAt;
        } else this.orders.push(await OrderStore.getOrder(publicID));
      } else
        this.orders.delete((order) => {
          return order.orderID == publicID;
        });
    });
  }

  public async rowClick(orderID: string) {
    (<ModalOrdine>this.$refs.modalOrdine).open(await Order.getOne(`orders/${orderID}`));
  }

  public orderStatusChanged(order: Order) {
    const orderInArray = this.orders.find((item) => {
      return item.orderID == order.ID;
    });
    if (orderInArray !== undefined) orderInArray.status = order.lastStatus.status;
  }

  public beforeDestroy() {
    this.onOrderStatusChanged?.dispose();
  }
}
</script>
