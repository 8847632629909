import Product from './Product';
import { Type } from 'class-transformer';
import ProductSize from './ProductSize';

export default class Item {
    public ID: string;
    @Type(() => Product)
    public product: Product;
    public quantity: number;
    @Type(() => ProductSize)
    public size: ProductSize;
}
