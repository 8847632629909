import RequestManager from './RequestManager';
import { ProducerOrderStatuses } from './ProducerOrderStatus';
import Item from './Item';
import { Type } from 'class-transformer';
import UserBusiness from './UserBusiness/UserBusiness';

export default class ProducerOrder extends RequestManager {
    public ID: string;
    @Type(() => Number)
    public status: ProducerOrderStatuses;
    @Type(() => Date)
    public createdAt: Date;
    @Type(() => Date)
    public dueDate: Date;
    public customId?: string;
    public notes?: string;
    public address?: string;
    @Type(() => Item)
    public items: Item[];
    @Type(() => UserBusiness)
    public userBusiness?: UserBusiness;

    constructor() {
        super();

        this.ID = '';
        this.status = ProducerOrderStatuses.New;
        this.createdAt = new Date();
        this.dueDate = new Date();
        this.customId = '';
        this.notes = '';
        this.address = '';
        this.items = [];
    }

    public async create(): Promise<string> {
        const createRequest = new CreateRequest();

        this.customId?.trim();
        this.notes?.trim();
        this.address?.trim();

        if (this.customId != null && this.customId.length > 0) {
            createRequest.customId = this.customId;
        }

        if (this.notes != null && this.notes.length > 0) {
            createRequest.notes = this.notes;
        }

        if (this.address != null && this.address.length > 0) {
            createRequest.address = this.address;
        }

        createRequest.dueDate = this.dueDate;
        createRequest.items = this.items.map((item) => {
            return { sizeId: item.size.ID, quantity: item.quantity };
        });

        const result = await createRequest.postOneGetOther(CreateResponse, 'producers/orders');
        this.ID = result.ID;
        return result.ID;
    }

    public async update(): Promise<void> {
        const updateRequest = new CreateRequest();

        this.customId?.trim();
        this.notes?.trim();
        this.address?.trim();

        if (this.customId != null && this.customId.length > 0) {
            updateRequest.customId = this.customId;
        }

        if (this.notes != null && this.notes.length > 0) {
            updateRequest.notes = this.notes;
        }

        if (this.address != null && this.address.length > 0) {
            updateRequest.address = this.address;
        }

        updateRequest.dueDate = this.dueDate;
        updateRequest.items = this.items.map((item) => {
            return { sizeId: item.size.ID, quantity: item.quantity };
        });

        await updateRequest.put(`producers/orders/${this.ID}`);
    }

    public static async getOrders(): Promise<ProducerOrder[]> {
        return await ProducerOrder.getMany('producers/orders');
    }

    public static async getOrder(id: string) {
        return await ProducerOrder.getOne(`producers/orders/${id}`);
    }
}

class CreateRequest extends RequestManager {
    public dueDate: Date;
    public customId?: string;
    public notes?: string;
    public address?: string;
    public items: Array<{ sizeId: string; quantity: number }>;
}

class CreateResponse {
    public readonly ID: string;
}
