import UserBusiness, { LoginRequest, LoginResponse, UserBusinessUpdate } from './entity/UserBusiness/UserBusiness';
import axios, { AxiosError } from 'axios';
import Vue from 'vue';
import SocketIO from 'socket.io-client';
import { plainToInstance } from 'class-transformer';
import { RuntimeEventSubscriber } from './util/Helper';
import { OrderNotification, UpdateStore } from './entity/Store';
import { TypedEvent } from './TypedEvent';
import { router } from './main';
import Store from '@/entity/Store';
import UserProducer from './entity/UserProducer';

export default class Session {
    private static _instance: Session;
    public static get instance() {
        if (!this._instance) this._instance = new Session();

        return this._instance;
    }

    private _userBusiness = null;
    public get userBusiness(): UserBusiness {
        if (this._userBusiness == null) {
            const userRaw = localStorage.getItem('user');

            if (userRaw !== '') {
                this._userBusiness = plainToInstance(UserBusiness, JSON.parse(userRaw));
                this.socketConnect();
            }
        }

        return this._userBusiness;
    }
    public set userBusiness(value) {
        localStorage.setItem('user', JSON.stringify(value));
        this._userBusiness = value;
    }

    private _store = null;
    public get store(): Store {
        return this.userBusiness.store;
    }
    public set store(value) {
        let user = this.userBusiness;
        user.store = value;

        localStorage.setItem('user', JSON.stringify(user));
        this._userBusiness = user;
    }

    private _userProducer = null;
    public get userProducer(): UserProducer {
        if (this._userProducer == null) {
            const userRaw = localStorage.getItem('userProducer');

            if (userRaw !== '') {
                this._userProducer = plainToInstance(UserProducer, JSON.parse(userRaw));
            }
        }

        return this._userProducer;
    }
    public set userProducer(value) {
        localStorage.setItem('userProducer', JSON.stringify(value));
        this._userProducer = value;
    }

    private _orderNotifications = new Array<OrderNotification>();
    public get orderNotifications(): Array<OrderNotification> {
        const rawNotifications = localStorage.getItem('notifications');

        if (!Object.isEmpty(rawNotifications)) {
            this._orderNotifications = plainToInstance(OrderNotification, <any[]>JSON.parse(rawNotifications));
        }

        return this._orderNotifications;
    }

    public get storeID(): string {
        return this.userBusiness?.store?.ID;
    }

    public get isPromonord(): boolean {
        return this.storeID === 'GAWDS';
    }

    public get isProducer(): boolean {
        return this.userProducer !== null;
    }

    public socket = SocketIO(process.env.SOCKET_URL, {
        autoConnect: false,
        transports: ['websocket'],
    });

    public readonly onNewOrder = new TypedEvent<OrderNotification>();
    public readonly onOrderStatusChanged = new TypedEvent<string>();
    public readonly onUserChange = new TypedEvent<void>();
    public readonly onLogin = new TypedEvent<void>();

    private constructor() {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error: AxiosError) => {
                if (error.response?.status === 401) {
                    this.resetSession();
                    router.push('/login');
                } else if (error.response?.status === 403) {
                    router.back();
                } else {
                    throw error;
                }
            },
        );

        axios.defaults.headers.common.authorization = Vue.cookies.get('token')?.toString();

        this.socketConnect();
    }

    public async login(email: string, password: string): Promise<UserBusiness> {
        const loginRequest = new LoginRequest();
        loginRequest.email = email;
        loginRequest.password = password;

        const loginResponse = await loginRequest.postOneGetOther(LoginResponse, 'userbusinesses/login');
        const token = `Bearer ${loginResponse.token}`;

        this.userBusiness = new UserBusiness(loginResponse);
        axios.defaults.headers.common.authorization = token;
        Vue.cookies.set('token', token, { secure: true });

        await this.setNotifications();

        this.socketConnect();
        this.onLogin.emit();

        return this.userBusiness;
    }

    public async producerLogin(email: string, password: string): Promise<UserProducer> {
        const loginRequest = new LoginRequest();
        loginRequest.email = email;
        loginRequest.password = password;

        const loginResponse = await loginRequest.postOneGetOther(UserProducer, 'userproducers/login');
        const token = `Bearer ${(loginResponse as any).token}`;
        delete (loginResponse as any).token;

        axios.defaults.headers.common.authorization = token;
        Vue.cookies.set('token', token, { secure: true });

        this.userProducer = loginResponse;

        return loginResponse;
    }

    public async updateUser(user: UserBusinessUpdate): Promise<void> {
        await UserBusiness.update(this.userBusiness.ID, user);

        this.userBusiness.firstName = user.firstname;
        this.userBusiness.lastName = user.lastname;

        this.userBusiness = this.userBusiness;

        this.onUserChange.emit();
    }

    public async updateStore(store: UpdateStore): Promise<void> {
        await this.userBusiness.store.update(store);

        Object.assignProperties(this.userBusiness.store, store, true);
        this.userBusiness = this.userBusiness;
    }

    public logout() {
        this.resetSession();
        router.push('/login');
    }

    private socketConnect() {
        if (!this.checkToken() || this.socket.connected || this.socket.active) {
            return;
        }

        this.socket.on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
                this.socketConnect();
            }
        });

        this.socket.auth = { token: Vue.cookies.get('token') };
        this.socket.connect();

        this.socketSubscribe();
    }

    private socketSubscribe() {
        this.socket.on('newOrder', async (rawData) => {
            const data = plainToInstance(OrderNotification, rawData);

            if (this.userBusiness.permission.canReceiveNotifications) await this.setNotifications();

            this.onNewOrder.emit(data);
        });

        this.socket.on('onOrderRead', async (data) => {
            await this.setNotifications();

            RuntimeEventSubscriber.emit('onOrderRead', data);
        });

        this.socket.on('orderStatusChanged', (data) => {
            RuntimeEventSubscriber.emit('orderStatusChanged', data);
            this.onOrderStatusChanged.emit(data);
        });
    }

    private async setNotifications(): Promise<void> {
        this._orderNotifications.splice(0, this._orderNotifications.length);
        this._orderNotifications.push(...(await this.userBusiness.store.getNotifications()));

        this._orderNotifications = this._orderNotifications.sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
        });
        localStorage.setItem('notifications', JSON.stringify(this._orderNotifications));
    }

    private addNotification(notification: OrderNotification | OrderNotification[]) {
        if (Array.isArray(notification)) {
            this._orderNotifications.push(...notification);
        } else {
            this._orderNotifications.push(notification);
        }

        this._orderNotifications = this._orderNotifications.sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
        });
        localStorage.setItem('notifications', JSON.stringify(this._orderNotifications));
    }

    private removeNotification(notificationID: string) {
        this._orderNotifications.delete((order) => {
            return order.ID == notificationID;
        });

        this._orderNotifications = this._orderNotifications.sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
        });
        localStorage.setItem('notifications', JSON.stringify(this._orderNotifications));
    }

    public checkToken(): boolean {
        if (!Vue.cookies.isKey('token')) {
            this.resetSession();
            return false;
        }

        return true;
    }

    private resetSession() {
        axios.defaults.headers.common.authorization = null;
        this.userBusiness = null;
        this.userProducer = null;

        localStorage.removeItem('notifications');
        localStorage.removeItem('user');

        this._orderNotifications = new Array<OrderNotification>();

        Vue.cookies.remove('token');

        this.socket.disconnect();
        this.socket = null;
        this.socket = SocketIO(process.env.SOCKET_URL, { autoConnect: false, transports: ['websocket'] });
    }
}
