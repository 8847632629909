<template>
  <div>
    <navbar v-model="showNavbar">
      <div class="navbar-wrapper">
        <div class="navbar-minimize">
          <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
            <i class="
                nc-icon nc-bullet-list-67
                text-center
                visible-on-sidebar-mini
              " />
            <i class="
                nc-icon nc-align-center
                text-center
                visible-on-sidebar-regular
              " />
          </button>
        </div>
        <div class="navbar-toggle">
          <navbar-toggle-button @click.native="toggleSidebar"></navbar-toggle-button>
        </div>
        <p class="navbar-brand">Easyfito control panel</p>
      </div>

      <template slot="navbar-menu">
        <ul class="navbar-nav">
          <drop-down v-if="canShowNotifications" tag="li" position="right" direction="none"
            class="nav-item btn-rotate dropdown">
            <a slot="title" slot-scope="{ isOpen }" class="nav-link dropdown-toggle" data-toggle="dropdown"
              aria-haspopup="true" :aria-expanded="isOpen">
              <el-badge :value="notificationsCount" :max="99" :hidden="notificationsCount === 0">
                <i class="fas fa-bell"></i>
                <p>
                  <span class="d-lg-none d-md-block">Notifiche</span>
                </p>
              </el-badge>
            </a>
            <div style="max-height: 350px; overflow-y: scroll">
              <p v-if="notificationsCount == 0" style="margin: 10px; color: #66615b">
                <span class="fas fa-bed" /> Tutto è tranquillo, nessuna notifica
              </p>
              <button class="dropdown-item" style="cursor: pointer" v-for="notification in notifications"
                :key="notification.ID" v-on:click="btnClicked(notification)">
                <div class="container" style="padding-left: 0 !important">
                  <div class="row">
                    <div class="col-4 my-auto">
                      <span class="fa fa-archive" style="font-size: 20px" />
                    </div>
                    <div class="col-8">
                      <div class="row">
                        <div class="col-12">
                          <b style="font-size: 16px">Nuovo ordine</b>
                        </div>
                        <div class="col-12">
                          {{ notification.ID }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </drop-down>
          <li class="nav-item">
            <a class="nav-link btn-rotate" @click="editUser" style="cursor: pointer">
              <i class="fas fa-cog"></i>
              <p>
                <span class="d-lg-none d-md-block">Account</span>
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="logout" style="cursor: pointer">
              <i class="fas fa-sign-out-alt"></i>
              <p>
                <span class="d-lg-none d-md-block">Logout</span>
              </p>
            </a>
          </li>
        </ul>
      </template>
    </navbar>

    <EditCurrentUser ref="editCurrentUser" />
    <ModalOrdine ref="modalOrdine" @orderStatusChanged="orderStatusChanged" />
  </div>
</template>
<script lang='ts'>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Navbar, NavbarToggleButton } from "@/components/UIComponents";
import Order from "@/entity/Order";
import Session from "@/Session";
import ModalOrdine from "@/views/Modal/ModalOrdine";
import { RuntimeEventSubscriber } from "@/util/Helper";
import { OrderNotification } from "@/entity/Store";
import EditCurrentUser from "@/views/Modal/EditCurrentUser/EditCurrentUser";

@Component({
  components: {
    Navbar,
    NavbarToggleButton,
    ModalOrdine: require("@/views/Modal/ModalOrdine.vue").default,
    EditCurrentUser: require("@/views/Modal/EditCurrentUser/EditCurrentUser.vue").default,
  },
})
export default class TopNavbar extends Vue {
  $refs: {
    modalOrdine: ModalOrdine;
    editCurrentUser: EditCurrentUser;
  };

  private activeNotifications = false;
  public showNavbar = false;

  public canShowNotifications = false;
  public notificationsCount = 0;
  public notifications = new Array<OrderNotification>();

  public async btnClicked(orderNotification: OrderNotification) {
    this.$refs.modalOrdine.open(
      await Order.getOne(`orders/${orderNotification.ID}`)
    );

    Session.instance.socket.emit("newOrderClicked", orderNotification.ID);
  }

  public logout() {
    try {
      Session.instance.logout();
    } catch { }
  }

  public orderStatusChanged(order: Order) {
    RuntimeEventSubscriber.emit("orderStatusChanged", order.ID);
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public toggleNotificationDropDown() {
    this.activeNotifications = !this.activeNotifications;
  }

  public closeDropDown() {
    this.activeNotifications = false;
  }

  public toggleSidebar() {
    (<any>this).$sidebar.displaySidebar(!(<any>this).$sidebar.showSidebar);
  }

  public hideSidebar() {
    (<any>this).$sidebar.displaySidebar(false);
  }

  public minimizeSidebar() {
    (<any>this).$sidebar.toggleMinimize();
  }

  public toggleNavbar() {
    this.showNavbar = !this.showNavbar;
  }

  private notificationEvent() {
    this.notificationsCount = Session.instance.orderNotifications.length;
    this.notifications = Session.instance.orderNotifications;
  }

  public editUser() {
    this.$refs.editCurrentUser.open();
  }

  mounted() {
    if (Session.instance.userBusiness?.permission?.canReceiveNotifications) {
      this.canShowNotifications = true;
      this.notificationEvent();

      Session.instance.onNewOrder.on(this.notificationEvent);
      RuntimeEventSubscriber.add("onOrderRead", this.notificationEvent);
    }
  }

  beforeDestroy() {
    RuntimeEventSubscriber.remove("onNewOrder", this.notificationEvent);
    RuntimeEventSubscriber.remove("onOrderRead", this.notificationEvent);
  }
}
</script>
<style scoped lang='scss'>
a {
  font-size: 16px !important;
}

p>span.d-lg-none.d-md-block {
  margin-left: 10px;
}
</style>
