import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ProducerOrderStatus, { ProducerOrderStatuses } from '../../entity/ProducerOrderStatus';
import ProducerOrder from '../../entity/ProducerOrder';
import { ElTable } from 'element-ui/types/table';
import Helper from '../../util/Helper';
import Session from '@/Session';
import ModalOrdineProduttore from '@/views/Modal/ModalOrdineProduttore.vue';
import { Disposable } from '@/TypedEvent';

interface IOrderTable {
    ID: string;
    createdAt: string;
    status: ProducerOrderStatuses;
    orderStatus: string;
    producer: string;
    storeName: string;
}

interface IPagination {
    perPage: number;
    currentPage: number;
    perPageOptions: Array<number>;
    total: number;
}

@Component({ name: 'ProducerOrderTable', components: { ModalOrdineProduttore } })
export default class ProducerOrderTable extends Vue {
    @Prop({ required: true, default: '' })
    public title: string;

    @Prop({ required: false, default: true })
    public hasStatusFilter: boolean;

    @Prop({ required: false, default: false })
    public hasDateFilter: boolean;

    private table: ElTable;

    public statusFilter = ProducerOrderStatus.getArrayObject('value', 'text');

    @Prop({ required: true, default: new Array<ProducerOrder>() })
    public orders: Array<ProducerOrder>;

    private searchResults = new Array<ProducerOrder>();
    private sortedData = new Array<ProducerOrder>();
    private filteredData = new Array<ProducerOrder>();

    public dateRangeResult = new Array<Date>();

    public searchQuery = '';

    private sortingOption = new Object();

    private isSorting = false;
    private isFiltering = false;
    public isSearching = false;
    private searched = false;

    public pickerDateSel = 'Creazione';

    @Prop({
        required: false,
        default: () => {
            return { perPage: 10, currentPage: 1, perPageOptions: [10, 25, 50], total: 0 };
        },
    })
    pagination: IPagination;

    private onOrderStatusChangedListener: Disposable;

    private get tableData(): IOrderTable[] {
        if (this.isSorting && !this.isFiltering) {
            return this.sortedData.map((order) => this.mapOrder(order));
        } else if (this.isFiltering || this.isSorting) {
            return this.filteredData.map((order) => this.mapOrder(order));
        } else if (this.searched) {
            return this.searchResults.map((order) => this.mapOrder(order));
        }

        return this.orders.map((order) => this.mapOrder(order));
    }

    public get pagedData() {
        return this.tableData.slice(this.from, this.to);
    }

    public get to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    }

    public get from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    }

    public get total() {
        this.pagination.total = this.tableData.length;
        return this.tableData.length;
    }

    public get canCreateOrder() {
        return Session.instance.userBusiness?.permission.canCreateOrder ?? false;
    }

    public get isPromonord(): boolean {
        return Session.instance.isPromonord;
    }

    public get isProducer(): boolean {
        return Session.instance.userProducer != null;
    }

    public rowClick(val?: IOrderTable) {
        this.table.setCurrentRow(null);

        if (val != null) {
            this.$emit('rowclick', val.ID);
        }
    }

    public async search(query: string | Object | KeyboardEvent) {
        if (typeof query === 'string') {
            this.searchQuery = query;
        }

        if (this.searchQuery === '' && this.searched) {
            this.searched = false;
            this.isSorting = false;
            this.isFiltering = false;

            this.table.clearSort();
            this.table.clearFilter();
            return;
        } else if (this.searchQuery === '' && !this.searched) {
            return;
        }

        // if (query instanceof KeyboardEvent && query.keyCode === 13) {
        //     this.isSearching = true;

        //     //this.searchFilter.find = this.searchQuery;
        //     this.searchResults = await OrderStore.getOrders({ find: this.searchQuery });

        //     this.searched = true;
        //     this.isSearching = false;
        // } else if (query instanceof Object && !(query instanceof KeyboardEvent)) {
        //     this.isSearching = true;

        //     //this.searchFilter.find = this.searchQuery;
        //     this.searchResults = await OrderStore.getOrders({ find: this.searchQuery });

        //     this.searched = true;
        //     this.isSearching = false;
        // }
    }

    public async searchOrders(query: string, callback: (data: Array<any>) => void) {
        // this.searchFilter.find = query;
        // let searchArray = await OrderStore.getOrders(this.searchFilter);
        // let result = [];
        // let map = new Map();
        // for (const item of searchArray) {
        //     if (item.orderID.toLowerCase().includes(query.toLowerCase())) {
        //         result.push({
        //             value: item.orderID,
        //             order: item,
        //         });
        //     } else if (!map.has(item.fullname)) {
        //         map.set(item.fullname, true);
        //         result.push({
        //             value: item.fullname,
        //             order: item,
        //         });
        //     }
        // }
        // callback(result);
    }

    public orderChanged(order: ProducerOrder) {
        const index = this.orders.findIndex((x) => x.ID === order.ID);
        if (index !== -1) {
            this.orders.splice(index, 1, order);
        } else {
            this.orders.push(order);
        }

        this.table.$forceUpdate();
    }

    @Watch('dateRangeResult')
    dateRangeResultChanged(val, oldVal) {
        console.log(val);
    }

    private lastSort: any;

    public forceSort() {
        if (!this.isSorting) {
            return;
        }

        this.onTableSort(this.lastSort);
    }

    public onTableSort(data) {
        if (data.order == null) {
            this.isSorting = false;
        } else {
            this.isSorting = true;
        }

        if (this.isSorting) {
            this.lastSort = data;
            this.sortingOption = data;

            if (data.prop == 'createdAt') {
                if (this.isFiltering) this.filteredData = this.orderArrayByDate(this.filteredData, 'createdAt', data.order);
                else if (!this.isFiltering) this.sortedData = this.orderArrayByDate(this.searched ? this.searchResults : this.orders, 'createdAt', data.order);
            } else if (data.prop == 'dueDate') {
                if (this.isFiltering) this.filteredData = this.orderArrayByDate(this.filteredData, 'dueDate', data.order);
                else if (!this.isFiltering) this.sortedData = this.orderArrayByDate(this.searched ? this.searchResults : this.orders, 'dueDate', data.order);
            }
        }
    }

    private lastFilter: any;

    public forceFilter() {
        if (!this.isFiltering) {
            return;
        }

        this.onTableFilter(this.lastFilter);
    }

    public onTableFilter(filters) {
        if (filters.status.length > 0) this.isFiltering = true;
        else this.isFiltering = false;

        if (this.isFiltering) {
            this.lastFilter = filters;

            if (this.isSorting) this.filteredData = this.filterArray(this.sortedData, filters);
            else this.filteredData = this.filterArray(this.searched ? this.searchResults : this.orders, filters);
        } else this.onTableSort(this.sortingOption);
    }

    public statusFormatter(row: IOrderTable): string {
        return ProducerOrderStatuses.toString(row.status);
    }

    public statusTagType(row: IOrderTable) {
        return ProducerOrderStatuses.tagColor(row.status);
    }

    public async tableNextPage(page: number) {
        if (page >= Math.ceil(this.total / this.pagination.perPage) - 1 && !this.searched) {
            this.isSearching = true;

            let current = this.orders.length + 1;

            // this.searchFilter.from = current;
            // this.searchFilter.to = current + this.pagination.perPage;
            // this.orders.push(...(await OrderStore.getOrders(this.searchFilter)));

            this.isSearching = false;
        }
    }

    private mapOrder(order: ProducerOrder): IOrderTable {
        return {
            ID: order.ID,
            createdAt: Helper.prittifyDate(order.createdAt),
            status: order.status,
            orderStatus: order.status.toString(),
            producer: order.items[0].size.product.producername,
            storeName: order.userBusiness?.store?.name ?? '',
        };
    }

    private orderArrayByDate(array: Array<ProducerOrder>, prop: string, order: 'ascending' | 'descending') {
        return array.slice().sort((a, b) => {
            if (order === 'ascending') {
                return a[prop] - b[prop];
            } else if (order === 'descending') {
                return b[prop] - a[prop];
            }
        });
    }

    private filterArray(array: Array<ProducerOrder>, filters) {
        return array.filter((item) => {
            return filters.status.includes(parseInt(<any>item.status));
        });
    }

    public openNewOrder() {
        (<any>this.$refs.modalOrdine).open();
    }

    public mounted() {
        console.log(this.isPromonord);

        this.table = <ElTable>this.$refs.ordiniTable;

        // if (this.searchFilter.status != null) {
        //     this.statusFilter = this.statusFilter.filter((x) => {
        //         return this.searchFilter.status.includes((<any>x).value);
        //     });
        // }

        this.onOrderStatusChangedListener = Session.instance.onOrderStatusChanged.on(this.onOrderStatusChanged);
    }

    private onOrderStatusChanged(id: string) {
        // TODO: update the status tag if is the id is in the table data
    }

    public beforeDestroy() {
        this.onOrderStatusChangedListener.dispose();
    }

    @Watch('pagination.perPage')
    private async paginationChanged(val: number, oldVal: number): Promise<void> {
        if (val === 1) {
            await this.tableNextPage(1);
        }
    }
}
