<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <order-table ref="orderTable" title="Ordini in arrivo" :orders="orders" :pagination="paginationOptions"
          :hasStatusFilter="false" v-on:rowclick="rowClick" />
      </div>
    </div>

    <ModalOrdine ref="modalOrdine" v-on:orderStatusChanged="orderStatusChanged" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import OrderStore from "../../entity/OrderStore";
import Order from "../../entity/Order";
import OrderStatus, { OrderStatuses } from "../../entity/OrderStatus";
import OrderTable from "../Tables/OrderTable.vue";
import ModalOrdine from "../Modal/ModalOrdine.vue";
import { Disposable } from "@/TypedEvent";
import Session from "@/Session";

@Component({
  components: {
    OrderTable,
    ModalOrdine,
  },
})
export default class OrdiniEvasi extends Vue {
  public orders = new Array<OrderStore>();
  public paginationOptions = { perPage: 25, perPageOptions: [25, 50, 100] };
  private onNewOrder: Disposable;

  public async rowClick(orderID: string) {
    (<ModalOrdine>this.$refs.modalOrdine).open(await Order.getOne(`orders/${orderID}`));
  }

  public orderStatusChanged(order: Order) {
    const orderInArray = this.orders.find((item) => {
      return item.orderID == order.ID;
    });
    if (orderInArray !== undefined) orderInArray.status = order.lastStatus.status;
  }

  async mounted() {
    this.orders = await OrderStore.getOrders({ status: [OrderStatuses.New] });

    this.onNewOrder = Session.instance.onNewOrder.on(async (order) => {
      this.orders.push(await OrderStore.getOrder(order.ID));

      (<OrderTable>this.$refs.orderTable).forceSort();
    });
  }

  beforeDestroy() {
    this.onNewOrder?.dispose();
  }
}
</script>
