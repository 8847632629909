export default [
    {
        name: 'Dashboard',
        icon: 'nc-icon nc-layout-11',
        path: '/dashboard',
    },
    {
        name: 'Ordini cliente',
        icon: 'nc-icon nc-app',
        children: [
            {
                name: 'Nuovi ordini',
                path: '/ordini/nuovi',
            },
            {
                name: 'Evasi',
                path: '/ordini/evasi',
            },
            {
                name: 'Rifiutati',
                path: '/ordini/rifiutati',
            },
        ],
    },
    {
        name: 'Ordini fornitore',
        icon: 'nc-icon nc-app',
        children: [
            {
                name: 'Ordini fornitore',
                path: '/ordini/fornitore',
            },
        ],
    },
    {
        name: 'Azienda',
        icon: 'nc-icon nc-briefcase-24',
        children: [
            {
                name: 'Gestione azienda',
                path: '/azienda/gestione-azienda',
                permission: { canManageStore: true },
            },
            {
                name: 'Gestione utenti',
                path: '/azienda/utenti',
                permission: { canManageAccounts: true },
            },
            // {
            //   name: 'Gestione permessi',
            //   path: '/azienda/permessi'
            // }
        ],
    },
    // {
    //   name: 'Notifiche',
    //   icon: 'nc-icon nc-bell-55',
    //   path: '/notifiche'
    // }
];
