import { Type } from 'class-transformer';
import Product from './Product';

export default class ProductSize {
    public ID: string;
    public package: string;
    public unit: string;
    public price?: number;
    @Type(() => Product)
    public product: Product;

    get getPackage() {
        return `${this.package} ${this.unit}`;
    }
}
