import _ from 'lodash';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { SlideYUpTransition } from 'vue2-transitions';
import PPagination from 'src/components/UIComponents/Pagination.vue';
import AddProductOrdine from '../Modal/ModalAddProductOrdine.vue';
import Order, { NewOrder } from '../../entity/Order';
import ProducerOrder from '../../entity/ProducerOrder';
import Product from '../../entity/Product';
import Helper from '../../util/Helper';
import ProductSize from '../../entity/ProductSize';
import Swal from 'sweetalert2';
import Item from '../../entity/Item';
import Session from '@/Session';
import UserCustomerStore from '@/entity/UserCustomer/UserCustomerStore';
import { ProducerOrderStatusUpdate, ProducerOrderStatuses } from '@/entity/ProducerOrderStatus';

class OrderTableRow extends Item {
    public edit: boolean = false;
    public loading: boolean = false;
    public saving: boolean = false;

    public selectedProduct: Product;
    public selectedSize: ProductSize;
    public searchResults = new Array<Product>();
    public newQuantity = 0;

    constructor(item: Item) {
        super();
        Object.assign(this, item);

        this.product = item.size.product;
        this.selectedProduct = this.product;
        this.selectedSize = this.size;
        this.newQuantity = this.quantity;
    }
}

@Component({
    name: 'ModalOrdineProduttore',
    components: { SlideYUpTransition, PPagination, AddProductOrdine },
})
export default class ModalOrdineProduttore extends Vue {
    public show = false;
    public order = new ProducerOrder();
    public originalOrder = new ProducerOrder();
    public rows = new Array<OrderTableRow>();
    public pdfLoading = false;
    public isNewOrder = false;
    public isOrderChanged = false;

    public componentKey = {
        table: 0,
        productSelect: 1,
        quantityInput: 2,
        saveBtn: 3,
        abortBtn: 4,
        delBtn: 5,
    };
    public newOrderCard = {
        loading: false,
    };
    public newOrder = new NewOrder();
    public rules = {
        product: {
            required: true,
        },
        productSize: {
            required: true,
        },
    };
    public newProduct = {
        visible: false,
    };
    public pagination = {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
    };
    public tableColumnSize = {
        quantity: 105,
        package: 105,
        buttons: 180,
    };
    public searchQuery = '';

    public get pagedData(): OrderTableRow[] {
        return this.rows.slice(this.from, this.to);
    }

    public get queriedData(): Item[] {
        if (!this.searchQuery) {
            this.pagination.total = this.rows.length;
            return this.pagedData;
        }

        const result = this.rows.filter((row) => {
            let isIncluded = false;

            for (const key of ['commercialName', 'producer', 'description']) {
                const rowValue = row.product[key].toString().toLowerCase();

                if (rowValue.includes && rowValue.includes(this.searchQuery.toString().toLowerCase())) {
                    isIncluded = true;
                }
            }

            return isIncluded;
        });

        this.pagination.total = result.length;

        return result.slice(this.from, this.to);
    }

    public get to(): number {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
    }

    public get from(): number {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
    }

    public get total(): number {
        this.pagination.total = this.rows.length;
        return this.pagination.total;
    }

    public get prittifyDueDate(): string {
        if (this.order?.dueDate != null) {
            return Helper.prittifyDate(this.order.dueDate);
        }
    }

    public get producer(): string {
        return this.order?.items?.[0]?.size?.product?.producername ?? '';
    }

    public get getStatusString(): string {
        if (this.order?.status != null) {
            return ProducerOrderStatuses.toString(this.order.status);
        }
    }

    public get isNew(): boolean {
        if (this.order?.status != null) {
            return this.order.status === ProducerOrderStatuses.New && Session.instance.isPromonord;
        }

        return false;
    }

    public get createdAt(): string {
        if (this.order?.createdAt != null) {
            return Helper.prittifyDate(this.order.createdAt);
        }

        return '';
    }

    public get canEdit(): boolean {
        if (this.isNewOrder) {
            return true;
        }

        if (this.order?.status != null) {
            if (Session.instance.isPromonord) {
                return ProducerOrderStatuses.canEdit(this.order.status);
            } else if (Session.instance.isProducer) {
                return this.order.status === ProducerOrderStatuses.UnderReviewByProducer;
            } else {
                return this.order.status === ProducerOrderStatuses.New;
            }
        }

        return false;
    }

    public get statusTagType(): string {
        if (this.order?.status == null) {
            return '';
        }

        return ProducerOrderStatuses.tagColor(this.order.status);
    }

    public get statusFormatter(): string {
        if (this.order?.status == null) {
            return '';
        }

        return ProducerOrderStatuses.toString(this.order.status);
    }

    private editingCounter = 0;
    private get isEditing(): boolean {
        return this.editingCounter > 0;
    }

    public get showOrderApprove(): boolean {
        if (Session.instance.isProducer) {
            return this.order.status === ProducerOrderStatuses.UnderReviewByProducer;
        }

        if (Session.instance.isPromonord) {
            return this.order.status === ProducerOrderStatuses.New;
        }

        return this.isNew || this.order.status === ProducerOrderStatuses.ModifiedByProducer || this.order.status === ProducerOrderStatuses.ModifiedByPromonord;
    }

    public get showCloseOrder(): boolean {
        if (Session.instance.isPromonord || Session.instance.isProducer) {
            return false;
        }

        return this.order.status === ProducerOrderStatuses.Approved;
    }

    public get getMail(): string {
        return this.order?.userBusiness?.mail ?? '';
    }

    public get getPhone(): string {
        return this.order?.userBusiness?.store?.tel ?? '';
    }

    public get getStoreName(): string {
        return this.order?.userBusiness?.store?.name;
    }

    public sendEmail(): void {
        window.location.href = 'mailto:' + this.getMail + '?subject=Easyfito';
    }

    public call(): void {
        window.location.href = 'tel:' + this.getPhone;
    }

    public async remoteMethod(query: string, row: OrderTableRow): Promise<void> {
        if (query !== '') {
            row.loading = true;

            try {
                row.searchResults = await Product.getMany(`products?search=${query}&producer=${row.product.producername}`);
                console.log(row.searchResults);
            } catch (error) {
                row.searchResults = [];
            } finally {
                row.loading = false;
            }
            // this.renderProductSelect();
        } else {
            // row.editProduct.products = [];
            // row.editProduct.options = [];
        }
    }

    public async productSelected(row: OrderTableRow): Promise<void> {
        row.selectedSize = row.selectedProduct?.productSizes?.[0] ?? null;
    }

    public async acceptOrder(): Promise<void> {
        if (Session.instance.isPromonord) {
            await this.newStatus(ProducerOrderStatuses.UnderReviewByProducer);
        } else {
            await this.newStatus(ProducerOrderStatuses.Approved);
        }
    }

    public async refuseOrder(): Promise<void> {
        await this.newStatus(ProducerOrderStatuses.Rejected);
    }

    public async closeOrder(): Promise<void> {
        await this.newStatus(ProducerOrderStatuses.Closed);
    }

    private async newStatus(orderStatus: ProducerOrderStatuses): Promise<void> {
        try {
            const newStatus = new ProducerOrderStatusUpdate();
            newStatus.status = orderStatus;
            await newStatus.postOne(`producers/orders/${this.order.ID}/status`);

            this.order.status = newStatus.status;

            this.$emit('orderStatusChanged', this.newOrder);

            this.$message({
                showClose: true,
                message: `Stato dell'ordine modificato`,
                type: 'success',
            });
        } catch (error) {
            console.error(error);

            this.$message({
                showClose: true,
                message: 'Qualcosa è andato storto, riprova',
                type: 'error',
            });
        }
    }

    public async createPDF(): Promise<void> {
        this.pdfLoading = true;

        try {
            const pdf = await Order.getPDF(this.order.ID);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(pdf.blob);
            link.download = pdf.filename;
            link.click();
            URL.revokeObjectURL(link.href);
            link.remove();
        } catch (error) {
            this.$message.error({ message: 'Errore nel download del PDF', showClose: true });
        }

        this.pdfLoading = false;
    }

    public addProductResult(result: { product: Product; size: ProductSize; quantity: number }): void {
        const productInArray = this.order.items.find((item) => {
            return item.size.ID === result.size.ID;
        });
        if (productInArray != null) {
            const productInRow = this.rows.find((item) => {
                return item.size.ID === result.size.ID;
            });
            if (productInRow != null) {
                productInRow.quantity += result.quantity;
                productInArray.quantity += result.quantity;
            }
        } else {
            const item = new Item();
            item.product = result.product;
            item.size = result.size;
            item.quantity = result.quantity;
            item.size.product = result.product;

            this.order.items.push(item);
            this.rows.push(new OrderTableRow(item));
        }

        // const productInArray = this.newOrder?.newItems?.find((item) => {
        //     return item.product.ID === result.product.ID && item.size.ID === result.size.ID;
        // });

        // if (productInArray != null) {
        //     productInArray.quantity += result.quantity;
        // } else {
        //     this.newOrder.newItems.push(plainToInstance(Item, result));
        // }
    }

    public async editMode(row: OrderTableRow, value: boolean): Promise<void> {
        if (value) {
            this.editingCounter++;

            row.loading = true;
            this.renderTable();

            await this.remoteMethod(row.product.commercialName, row);
            // row.editProduct.selected = row.editProduct.options[0].label;
            // this.productSelected(row.editProduct.options[0].value, row);

            // const productSize = row.editProductSize.options.find((item) => {
            //     return item.value === row.size.ID;
            // });

            // row.editProductSize.selected = productSize.label;
            // this.productSizeSelected(productSize.value, row);

            // row.editResult.quantity = row.quantity;

            if (this.isEditing) {
                this.tableColumnSize.quantity = 150;
                this.tableColumnSize.package = 130;
                this.tableColumnSize.buttons = 225;
            }

            row.loading = false;
        } else {
            this.editingCounter--;

            if (!this.isEditing) {
                this.tableColumnSize.quantity = 105;
                this.tableColumnSize.package = 105;
                this.tableColumnSize.buttons = 180;
            }
        }

        row.edit = value;
        this.renderTable();
    }

    public async save(row: OrderTableRow): Promise<void> {
        row.saving = true;
        try {
            const isValid = await this.$validator.validateAll(row.ID);

            if (isValid) {
                this.renderButtons();

                // if (this.isNewOrder) {
                const item = this.order.items.find((item) => {
                    return item.size.ID === row.size.ID;
                });

                if (item != null) {
                    row.quantity = row.newQuantity;
                    row.product = row.selectedProduct;
                    row.size = row.selectedSize;
                    row.size.product = row.selectedProduct;

                    item.quantity = row.quantity;
                    item.size = row.size;
                    item.size.product = row.product;
                }
                // }

                this.editMode(row, false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            row.saving = false;
        }
    }

    public remove(row: OrderTableRow): void {
        if (this.order.items.length === 1 && !this.isNewOrder) {
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: 'Impossibile cancellare tutti i prodotti.',
            });
        } else {
            this.order.items.delete((item) => {
                return item.size.ID === row.size.ID;
            });
            this.rows.delete(row);
        }
    }

    public confirmClose(): void {
        if (!this.isOrderChanged) {
            this.closeModal();
            return;
        }

        this.$confirm('Ci sono modifiche non salvate, chiudere ugualmente?', 'Modifiche non salvate', {
            confirmButtonText: 'SÌ',
            cancelButtonText: 'Annulla',
            type: 'warning',
        })
            .then(() => {
                this.closeModal();
            })
            .catch(() => {
                return;
            });
    }

    public async saveChanges(): Promise<void> {
        if (!this.isOrderChanged) {
            return;
        }

        const loading = this.$loading({
            text: this.isNewOrder ? 'Creazione in corso...' : 'Modifica in corso...',
            target: '#modalContent',
        });

        try {
            if (this.isNewOrder) {
                await this.order.create();
            } else {
                await this.order.update();
            }

            this.$message({
                showClose: true,
                message: this.isNewOrder ? 'Ordine creato correttamente' : 'Ordine modificato correttamente',
                type: 'success',
            });

            this.$emit('orderChanged', this.order);
            this.closeModal();
        } catch (error) {
            console.error(error);

            this.$message({
                showClose: true,
                message: 'Qualcosa è andato storto, riprova',
                type: 'error',
            });
        } finally {
            loading.close();
        }
    }

    private renderTable(): void {
        this.componentKey.table++;
    }

    private renderProductSelect(): void {
        this.componentKey.productSelect++;
    }

    private renderButtons(): void {
        this.componentKey.saveBtn++;
        this.componentKey.abortBtn++;
        this.componentKey.delBtn++;
    }

    public removeNowBtn(): void {
        setTimeout(() => {
            const elements = document.getElementsByClassName('el-button el-picker-panel__link-btn el-button--text el-button--mini'); //[0].remove();
            elements[0]?.remove();
        }, 0);
    }

    public getSummaries(): string[] {
        const sum = this.rows.reduce((sum, row) => {
            return sum + (row.quantity * row.size.price ?? 0);
        }, 0);

        return ['Totale', '', '', '', `€ ${sum}`];
    }

    public open(order?: ProducerOrder): void {
        Object.assign(this, this.initData());
        this.newOrder = null;
        this.isNewOrder = order == null;
        this.order = order ?? new ProducerOrder();
        this.originalOrder = _.cloneDeep(this.order);
        this.rows =
            order?.items.map((item) => {
                return new OrderTableRow(item);
            }) ?? [];
        this.show = true;
    }

    private closeModal(): void {
        this.show = false;
        this.$emit('close');
    }

    private initData() {
        return {
            editingCounter: 0,
            componentKey: {
                table: 0,
                productSelect: 1,
                quantityInput: 2,
                saveBtn: 3,
                abortBtn: 4,
                delBtn: 5,
            },
            newOrderCard: {
                loading: false,
            },
            rawUserCustomers: new Array<UserCustomerStore>(),
            userCustomers: new Array(),
            rows: new Array<OrderTableRow>(),
            selectedUserCustomerID: '',
            rules: {
                product: {
                    required: true,
                },
                productSize: {
                    required: true,
                },
            },
            newProduct: {
                visible: false,
            },
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [10, 25, 50],
                total: 0,
            },
            tableColumnSize: {
                quantity: 105,
                package: 105,
                buttons: 180,
            },
            searchQuery: '',
        };
    }

    mounted() {}

    @Watch('show')
    private showChanged(val: boolean) {
        let documentClasses = document.body.classList;
        if (val) {
            documentClasses.add('modal-open');
        } else {
            documentClasses.remove('modal-open');
        }
    }

    @Watch('order', { immediate: false, deep: true })
    private orderChanged(val) {
        if (val == null) {
            return;
        }

        this.isOrderChanged = !_.isEqual(_.omit(this.order, ['status']), _.omit(this.originalOrder, ['status']));
    }
}
